<!--
 * @Author       : JiangChao
 * @Date         : 2022-11-28 21:40:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-05 09:56:17
 * @Description  : 協議與隱私規定
-->
<template>
	<div class="container">
		<div class="f26">
			<div>服務內容<br />
				旅電共享行動電源 是由旅電科技有限公司（以下簡稱「本公司」）的手機應用程式或網站的用戶用於租用行動電源，包括行動電源租賃軟件的Web服務。除非本公司另有書面同意，本服務僅供您個人而非商業用途使用。<br />
			</div>
			<div class="item">
				註冊義務及個資規範<br />
				(1）註冊賬號<br />
				為了使用大部分服務，您必須註冊並維護一個活躍的個人用戶服務帳戶。本服務將提供個人資料頁面，以便您更新您的個人信息，例如您的姓名、電子郵件和手機號碼。您同意在帳戶中保留準確、完整和最新的信息。如您未能保持準確、完整和最新的帳戶信息，這可能導致您無法訪問和使用服務或本公司的終止條款。您對您帳戶下發生的所有活動負責，並且您同意始終保持帳戶用戶名和密碼的安全性和保密性。除非本公司書面許可，您只能擁有一個賬戶。<br />
				(2）用戶使用要求<br />
				您不得授權第三方使用您的帳戶。您不得轉讓或以其他方式將您的賬戶轉讓給任何其他人或其他實體。您同意在使用服務時遵守所有適用的法律，您只能以合法的方式使用服務。在您使用本服務時，您不會對任何其他方造成滋擾、煩擾、不便或財產損失。在某些情況下，您可能會被要求提供訪問或使用服務的身份證明。您明白如果您拒絕提供身份證明，您可能會被拒絕訪問或使用服務。<br />
				(3）簡訊<br />
				通過創建帳戶，您同意服務可能會向您發送信息文本（SMS）消息，作為使用服務的正常業務操作的一部分。如果您對從本公司接收短信（SMS）的消息有疑問，您可以通過pluginn_info@gmail.com與本公司聯繫。您確認選擇不接收文本（SMS）消息可能會影響您對“服務”的使用。<br />
				(4）個人資料<br />
				個人資料蒐集類別 i. 依「個人資料保護法之特定目的及個人資料之類別」規定，您為使用本服務提 供本公司之個人資料類別包括：姓名、行動電話、電子郵件地址、信用卡號碼 及電子票證號碼。 ii.
				個人資料如有登錄不實、不完整或錯誤者，本公司有權暫停或終止您使用本服 務之全部或一部分。<br />
				個人資料利用期限 i. 特定目的存續期間。 ii. 依相關法令所定（例如商業會計法等）或因執行業務所必須之保存期間或依個 別契約就資料之保存所定之保存年限（以期限最長者為準）。<br />
				個人資料利用地區：台灣地區<br />
				個人資料使用目的 i. 依「個人資料保護法之特定目的及個人資料之類別」規定，您的個人資料將使 用目的包括：(069)契約、類似契約或其他法律關係事務、(063)非公務機關依法
				定義務所進行個人資料之蒐集處理及利用、(090)消費者、客戶管理與服務、(135) 資（通）訊服務、(136)資（通）訊與資料庫管理、(137)資通安全與管理、(143)
				運動休閒業務、(157)調查、統計與研究分析、(180) 其他經營公共事業業務、 (181)其他經營合於營業登記項目或組織章程所定之業務。 ii. 您了解當您使用本服務時，您依第一項規定提供本公司之個人資料，將依前項
				規定之使用目的範圍內，供本公司、主管機關、關係企業及合作夥伴內部使用。<br />
			</div>
			<div class="item">
				使用服務範圍規範<br />
				(1)支付<br />
				您在旅電APP 上進行支付時，您可以選擇與旅電APP 合作的第三方支付機構（如街口支付、Google Pay、Wechat Pay、Apple
				Pay等支付通道）所提供的支付服务。支付功能本身並不收集您的個人信息，但我們需要將您的充值紀錄及對賬信息與這些支付即可共享以確認您的支付指令完成並支付。<br />
				(2)付款<br />
				您了解使用服務將可能會向您收取相關的服務費用。當您使用服務後，您將支付因使用服務所衍生的費用。在法律規定下，收費將包括適用的稅費。<br />
				首次使用需先支付押金，作為註冊過程的一部分，並在賬戶開始日期支付給本公司。保證金金額為384新台幣。<br />
				押金在結束訂單或是沒有未結帳訂單情況下可退還；處理退款時間需要1至14天。<br />
				訂單結束時會生成一筆訂單請求付款，消費者可使用上述各種行動支付清償訂單即可再次租借或是請求退回押金。<br />
				行動電源租借費用為每小時12新台幣，單日費用上限是48新台幣、每日24:00重置，八天未歸還本公司將有權直接扣除押金，行動電源將歸消費者所有。<br />
			</div>
			<div class="item">
				系統運作<br />
				當行動電源解鎖時，或者當使用者用手機應用程式解鎖行動電源時，使用者租賃行動電源的時間就會開始進行計時。當行動電源被歸還時，租賃期即結束。如果行動電源在租賃期後並未被退回及鎖定在合法和免費的停泊位置，本公司將收取額外費用。<br />
			</div>
			<div class="item">
				維護服務<br />
				公司負責行動電源的日常運行、維護和維修工作，但這並不意味著公司有義務確保所有可用的行動電源任何時候均處於無故障狀態。 使用者應該在使用行動電源之前確認部件的完整性、效率，並熟悉行動電源的性能。<br />
				如果使用者發現行動電源壞了，他/她將取消或停止使用服務，並通知公司有關問題。 如果使用者無視損壞的行動電源並繼續使用，使用者將對該行為及有關後果負責。<br />
			</div>
			<div class="item">
				所有權<br />
				本服務及其所有權利均為本公司的財產或本公司許可人的財產。 這些條款或您對服務的使用都不會向您轉讓或授予您任何權利：（i）在服務中或與服務有關的服務，除了上述授予的有限許可證;
				或（ii）以任何方式使用或引用本公司的公司名稱、標誌、產品和服務名稱、商標或服務標誌、或本公司許可人的標誌。<br />
				行動電源使用的限制及其他條款<br />
				(1) 陳述和保證<br />
				在本公司允許使用者參與本計劃並租賃行動電源前，使用者代表並保證向本公司承諾：<br />
				· 使用者熟悉與行動電源安全及合法操作的相關及所有適用的當地法規。<br />
				(2) 承諾和協議<br />
				在本公司允許使用者參與本計劃並租賃行動電源前，使用者代表並保證向本公司承諾：<br />
				· 使用者充分意識到在使用行動電源時不能在危險情況下使用，如使用時不能與水接觸、不能把行動電源接近火種、不能把行動電源折開等，使用者必須保持適當的警惕以避免此類事故。<br />
				· 使用者全權負責以謹慎合理的方式操作行動電源。<br />
				· 所有行動電源始終是本公司的專屬財產。<br />
				· 本公司無義務提供使用者使用行動電源服務有關的任何種類的保險，如果本公司有購買相關保險，使用者仍然對任何責任、財產損失、人身傷害、損害等作出賠償並負責所有罰款、損失和/或任何形式的費用。<br />
				· 如果使用者在操作或管有行動電源時造成財產損失或對另一方造成的傷害，使用者對此類損害或傷害負全部責任。<br />
				· 使用者應完好無缺地將行動電源退回本公司。<br />
				· 使用者對由於不正當使用或濫用行動電源造成的任何和所有損害以及損害賠償的費用承擔責任。<br />
				· 本公司為租賃行動電源提供方便，此類租賃只能由已同意本協議的所有條款和條件的人員使用。<br />
				(3) 要求<br />
				在本公司允許使用者參與本計劃並租賃行動電源前，使用者在任何使用行動電源（“使用要求”）期間應執行以下操作：<br />
				· 在使用前仔細檢查使用者希望租用的行動電源，以確保行動電源的運行狀況良好。<br />
				· 在進行預期用途之前測試行動電源的操作部件，包括但不限於電池和充電線。<br />
				· 當發現行動電源的任何缺陷、故障或需要維修的行動電源，應儘快通知公司客戶服務部。<br />
				· 如果行動電源被盜或在使用者使用行動電源時造成任何人身傷害事故，立即聯繫公司和當地警察.<br />
			</div>
			<div class="item">
				賠償<br />
				使用者違反本協議和所有與本協議相關或因此產生的聲明時，包括但不限於使用者違反本協議中規定的任何聲明、保證或協議，均須為本公司提供賠償和保護本公司對行動電源的租賃、維護、設計、使用或操作和/或公司網站，即使是由公司的疏忽造成的全部或部分損失，和/或其他人的疏忽，或是目前已知的還是未知的損害。根據本公司的意願，使用者將負責任何索賠的辯護和解決方案，並由使用者補償（前提是在此情況下，本公司可隨時選擇接管控制任何此類索賠的辯護和解決方案）。在任何情況下，使用者均不得在取得本公司書面同意的情況前解決任何索賠情況。<br />
			</div>
			<div class="item">
				廣告<br />
				您在本服務中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，您 因信任該廣告而受有損害者，除本公司明知或可得而知該廣告有不實之情事而仍為刊登者 外，本公司不負任何賠償責任。<br />
				智財權 本公司所使用之硬體、軟體或程式、網站上所有內容，包括但不限於著作、圖片、 檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依
				法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。 任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原
				工程、解編或反向組譯。若您欲引用或轉載前述內容，必須依法取得本公司或其他權利人 的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償
				責任。本公司及其關係企業為行銷宣傳本服務，就本服務相關之商品或服務名稱、圖樣等 （以下稱「旅電 商標」），依其註冊或使用之狀態，受商標法及公平交易法等之保護， 未經本公司事前書面同意，您同意不以任何方式使用「旅電
				商標」。<br />
			</div>
			<div class="item">
				終止協議<br />
				公司可隨時無原因地終止本協議及相關法律程序，或者向使用者於使用本系統時作出通知。<br />
				使用者放棄與任何此類合約終止相關的所有索賠、引起訴訟的費用和/或損害賠償。如果本協議由於特定原因而被終止，使用者將無權退還未使用的租賃期間支付的任何金額。
				使用者可隨時終止使用者的租賃計劃;然而，如果使用者已使用服務，將不獲退回已於租賃期間所使用的款項。<br />
			</div>
			<div class="item">
				殘存條款<br />
				本協議有關限制和排除責任、放棄、承擔風險、擔保和賠償責任的所有條款應在本協議終止後仍然有效，並且在本協議終止或到期時未支付的所有款項應依然被視為應付款項。<br />
			</div>
			<div class="item">
				免責聲明<br />
				本服務係提供有行動電源租借服務，如因不可抗力之因素 (含 天災及電信業者因素) 及系統必要之維修保養而暫時中斷服務者，對於因使用(或無法 使用) 本服務而造成的損害，本公司不負任何賠償責任。<br />
				本公司得修改或中止各項提供之服務內容，並於網站公告後生效(不須經過個別通知)，您不得因此要求任何賠償。<br />
			</div>
			<div class="item">
				條款增訂、修改及終止<br />
				本服務條款如有增訂、修改或終止致您使用本服務之權益有異動者，本公司將於本服務之網站、旅電APP公告，如您有任何意見歡迎隨時與本公司反應。<br />
				準據法及管轄法院<br />
				本服務條款構成您與本公司就您使用本服務之完整合意，取代您先前與本公司有關本服務所為之任何約定；您於使用本服務、關係企業服務、第三方內容或軟體時，可能
				亦須適用額外條款或條件。本服務條款之解釋與適用，以及與本服務條款有關的爭
				議，除法律另有規定者外，均應依照中華民國法律予以處理，並以臺灣臺北地方法院 為第一審管轄法院。<br />
				(2)
				本公司未行使或執行本服務條款任何權利或規定，不構成前開權利或規定之棄權。若任何本服務條款規定，經有管轄權之法院認定無效，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定仍應完全有效。服務內容<br />
				旅電共享行動電源 是由旅電科技有限公司（以下簡稱「本公司」）的手機應用程式或網站的用戶用於租用行動電源，包括行動電源租賃軟件的Web服務。除非本公司另有書面同意，本服務僅供您個人而非商業用途使用。<br />
			</div>
			<div class="item">
				註冊義務及個資規範<br />
				(1）註冊賬號<br />
				為了使用大部分服務，您必須註冊並維護一個活躍的個人用戶服務帳戶。本服務將提供個人資料頁面，以便您更新您的個人信息，例如您的姓名、電子郵件和手機號碼。您同意在帳戶中保留準確、完整和最新的信息。如您未能保持準確、完整和最新的帳戶信息，這可能導致您無法訪問和使用服務或本公司的終止條款。您對您帳戶下發生的所有活動負責，並且您同意始終保持帳戶用戶名和密碼的安全性和保密性。除非本公司書面許可，您只能擁有一個賬戶。<br />
				(2）用戶使用要求<br />
				您不得授權第三方使用您的帳戶。您不得轉讓或以其他方式將您的賬戶轉讓給任何其他人或其他實體。您同意在使用服務時遵守所有適用的法律，您只能以合法的方式使用服務。在您使用本服務時，您不會對任何其他方造成滋擾、煩擾、不便或財產損失。在某些情況下，您可能會被要求提供訪問或使用服務的身份證明。您明白如果您拒絕提供身份證明，您可能會被拒絕訪問或使用服務。<br />
				(3）簡訊<br />
				通過創建帳戶，您同意服務可能會向您發送信息文本（SMS）消息，作為使用服務的正常業務操作的一部分。如果您對從本公司接收短信（SMS）的消息有疑問，您可以通過pluginn_info@gmail.com與本公司聯繫。您確認選擇不接收文本（SMS）消息可能會影響您對“服務”的使用。<br />
				(4）個人資料<br />
				個人資料蒐集類別 i. 依「個人資料保護法之特定目的及個人資料之類別」規定，您為使用本服務提 供本公司之個人資料類別包括：姓名、行動電話、電子郵件地址、信用卡號碼 及電子票證號碼。 ii.<br />
				個人資料如有登錄不實、不完整或錯誤者，本公司有權暫停或終止您使用本服 務之全部或一部分。<br />
				個人資料利用期限 i. 特定目的存續期間。 ii. 依相關法令所定（例如商業會計法等）或因執行業務所必須之保存期間或依個 別契約就資料之保存所定之保存年限（以期限最長者為準）。<br />
				個人資料利用地區：台灣地區<br />
				個人資料使用目的 i. 依「個人資料保護法之特定目的及個人資料之類別」規定，您的個人資料將使 用目的包括：(069)契約、類似契約或其他法律關係事務、(063)非公務機關依法
				定義務所進行個人資料之蒐集處理及利用、(090)消費者、客戶管理與服務、(135) 資（通）訊服務、(136)資（通）訊與資料庫管理、(137)資通安全與管理、(143)
				運動休閒業務、(157)調查、統計與研究分析、(180) 其他經營公共事業業務、 (181)其他經營合於營業登記項目或組織章程所定之業務。 ii. 您了解當您使用本服務時，您依第一項規定提供本公司之個人資料，將依前項
				規定之使用目的範圍內，供本公司、主管機關、關係企業及合作夥伴內部使用。
			</div>
			<div class="item">
				使用服務範圍規範<br />
				(1)支付<br />
				您在旅電APP 上進行支付時，您可以選擇與旅電APP 合作的第三方支付機構（如街口支付、Google Pay、Wechat Pay、Apple
				Pay等支付通道）所提供的支付服务。支付功能本身並不收集您的個人信息，但我們需要將您的充值紀錄及對賬信息與這些支付即可共享以確認您的支付指令完成並支付。<br />
				(2)付款<br />
				您了解使用服務將可能會向您收取相關的服務費用。當您使用服務後，您將支付因使用服務所衍生的費用。在法律規定下，收費將包括適用的稅費。<br />
				首次使用需先支付押金，作為註冊過程的一部分，並在賬戶開始日期支付給本公司。保證金金額為384新台幣。<br />
				押金在結束訂單或是沒有未結帳訂單情況下可退還；處理退款時間需要1至14天。<br />
				訂單結束時會生成一筆訂單請求付款，消費者可使用上述各種行動支付清償訂單即可再次租借或是請求退回押金。<br />
				行動電源租借費用為每小時12新台幣，單日費用上限是48新台幣、每日24:00重置，八天未歸還本公司將有權直接扣除押金，行動電源將歸消費者所有。
			</div>
			<div class="item">
				系統運作<br />
				當行動電源解鎖時，或者當使用者用手機應用程式解鎖行動電源時，使用者租賃行動電源的時間就會開始進行計時。當行動電源被歸還時，租賃期即結束。如果行動電源在租賃期後並未被退回及鎖定在合法和免費的停泊位置，本公司將收取額外費用。
			</div>
			<div class="item">
				維護服務<br />
				公司負責行動電源的日常運行、維護和維修工作，但這並不意味著公司有義務確保所有可用的行動電源任何時候均處於無故障狀態。 使用者應該在使用行動電源之前確認部件的完整性、效率，並熟悉行動電源的性能。<br />
				如果使用者發現行動電源壞了，他/她將取消或停止使用服務，並通知公司有關問題。 如果使用者無視損壞的行動電源並繼續使用，使用者將對該行為及有關後果負責。
			</div>
			<div class="item">
				所有權<br />
				本服務及其所有權利均為本公司的財產或本公司許可人的財產。 這些條款或您對服務的使用都不會向您轉讓或授予您任何權利：（i）在服務中或與服務有關的服務，除了上述授予的有限許可證;
				或（ii）以任何方式使用或引用本公司的公司名稱、標誌、產品和服務名稱、商標或服務標誌、或本公司許可人的標誌。<br />
				行動電源使用的限制及其他條款<br />
				(1) 陳述和保證<br />
				在本公司允許使用者參與本計劃並租賃行動電源前，使用者代表並保證向本公司承諾：
				· 使用者熟悉與行動電源安全及合法操作的相關及所有適用的當地法規。<br />
				(2) 承諾和協議<br />
				在本公司允許使用者參與本計劃並租賃行動電源前，使用者代表並保證向本公司承諾：
				· 使用者充分意識到在使用行動電源時不能在危險情況下使用，如使用時不能與水接觸、不能把行動電源接近火種、不能把行動電源折開等，使用者必須保持適當的警惕以避免此類事故。
				· 使用者全權負責以謹慎合理的方式操作行動電源。
				· 所有行動電源始終是本公司的專屬財產。
				· 本公司無義務提供使用者使用行動電源服務有關的任何種類的保險，如果本公司有購買相關保險，使用者仍然對任何責任、財產損失、人身傷害、損害等作出賠償並負責所有罰款、損失和/或任何形式的費用。
				· 如果使用者在操作或管有行動電源時造成財產損失或對另一方造成的傷害，使用者對此類損害或傷害負全部責任。
				· 使用者應完好無缺地將行動電源退回本公司。
				· 使用者對由於不正當使用或濫用行動電源造成的任何和所有損害以及損害賠償的費用承擔責任。
				· 本公司為租賃行動電源提供方便，此類租賃只能由已同意本協議的所有條款和條件的人員使用。<br />
				(3) 要求<br />
				在本公司允許使用者參與本計劃並租賃行動電源前，使用者在任何使用行動電源（“使用要求”）期間應執行以下操作：
				· 在使用前仔細檢查使用者希望租用的行動電源，以確保行動電源的運行狀況良好。
				· 在進行預期用途之前測試行動電源的操作部件，包括但不限於電池和充電線。
				· 當發現行動電源的任何缺陷、故障或需要維修的行動電源，應儘快通知公司客戶服務部。
				· 如果行動電源被盜或在使用者使用行動電源時造成任何人身傷害事故，立即聯繫公司和當地警察.
			</div>
			<div class="item">
				賠償<br />
				使用者違反本協議和所有與本協議相關或因此產生的聲明時，包括但不限於使用者違反本協議中規定的任何聲明、保證或協議，均須為本公司提供賠償和保護本公司對行動電源的租賃、維護、設計、使用或操作和/或公司網站，即使是由公司的疏忽造成的全部或部分損失，和/或其他人的疏忽，或是目前已知的還是未知的損害。根據本公司的意願，使用者將負責任何索賠的辯護和解決方案，並由使用者補償（前提是在此情況下，本公司可隨時選擇接管控制任何此類索賠的辯護和解決方案）。在任何情況下，使用者均不得在取得本公司書面同意的情況前解決任何索賠情況。
			</div>
			<div class="item">
				廣告<br />
				您在本服務中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，您 因信任該廣告而受有損害者，除本公司明知或可得而知該廣告有不實之情事而仍為刊登者 外，本公司不負任何賠償責任。
				智財權 本公司所使用之硬體、軟體或程式、網站上所有內容，包括但不限於著作、圖片、 檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依
				法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。 任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原
				工程、解編或反向組譯。若您欲引用或轉載前述內容，必須依法取得本公司或其他權利人 的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償
				責任。本公司及其關係企業為行銷宣傳本服務，就本服務相關之商品或服務名稱、圖樣等 （以下稱「旅電 商標」），依其註冊或使用之狀態，受商標法及公平交易法等之保護， 未經本公司事前書面同意，您同意不以任何方式使用「旅電
				商標」。
			</div>
			<div class="item">
				終止協議<br />
				公司可隨時無原因地終止本協議及相關法律程序，或者向使用者於使用本系統時作出通知。
				使用者放棄與任何此類合約終止相關的所有索賠、引起訴訟的費用和/或損害賠償。如果本協議由於特定原因而被終止，使用者將無權退還未使用的租賃期間支付的任何金額。
				使用者可隨時終止使用者的租賃計劃;然而，如果使用者已使用服務，將不獲退回已於租賃期間所使用的款項。
			</div>
			<div class="item">
				殘存條款<br />
				本協議有關限制和排除責任、放棄、承擔風險、擔保和賠償責任的所有條款應在本協議終止後仍然有效，並且在本協議終止或到期時未支付的所有款項應依然被視為應付款項。
			</div>
			<div class="item">
				免責聲明<br />
				本服務係提供有行動電源租借服務，如因不可抗力之因素 (含 天災及電信業者因素) 及系統必要之維修保養而暫時中斷服務者，對於因使用(或無法 使用) 本服務而造成的損害，本公司不負任何賠償責任。<br />
				本公司得修改或中止各項提供之服務內容，並於網站公告後生效(不須經過個別通知)，您不得因此要求任何賠償。
			</div>
			<div class="item">
				條款增訂、修改及終止<br />
				本服務條款如有增訂、修改或終止致您使用本服務之權益有異動者，本公司將於本服務之網站、旅電APP公告，如您有任何意見歡迎隨時與本公司反應。<br />
				準據法及管轄法院<br />
				本服務條款構成您與本公司就您使用本服務之完整合意，取代您先前與本公司有關本服務所為之任何約定；您於使用本服務、關係企業服務、第三方內容或軟體時，可能
				亦須適用額外條款或條件。本服務條款之解釋與適用，以及與本服務條款有關的爭
				議，除法律另有規定者外，均應依照中華民國法律予以處理，並以臺灣臺北地方法院 為第一審管轄法院。<br />
				(2)
				本公司未行使或執行本服務條款任何權利或規定，不構成前開權利或規定之棄權。若任何本服務條款規定，經有管轄權之法院認定無效，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定仍應完全有效。
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Agreement",
	data() {
		return {};
	},
	methods: {},
	props: {},
	components: {},
	computed: {},
	mounted() {},
	created() {},
};
</script>

<style scoped lang="less">
.item {
	margin-top: 25px;
	line-height: 25px;
}
.f26 {
	font-size: 13px;
}
</style>
